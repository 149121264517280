import {SecretsManagerClient, GetSecretValueCommand,} from "@aws-sdk/client-secrets-manager";

export async function AWSSecrets() {

    const secret_name = "BIA-CodeLab-Cog-DEV";

    const client = new SecretsManagerClient({
        region: "eu-west-1",
    });

    let response;

    try {
        response = await client.send(
            new GetSecretValueCommand({
                SecretId: secret_name,
                VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
            })
        );
    } catch (error) {
        // For a list of exceptions thrown, see
        // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
        throw error;
    }

    const secret = response.SecretString;

}