import {Outlet, useNavigate} from "react-router-dom";
import React from "react";
import {AbsoluteCenter, Box, Button, Center, Grid, GridItem} from "@chakra-ui/react";
import BiaLogo from "../assets/Bia.jpg";
import StartButton from "../modules/StartButton";

export default function Portal() {

    const navigate = useNavigate();

    return (
        <Box width={1024} margin="auto" axis='both' marginTop={50}>
            <Box bg='#48BB78' w='100%' p={2} color='white'>
                <Box bg='#F56565' w='100%' p={2} color='white'>
                    <Box bg='#4299E1' w='100%' p={2} color='white'>
                        <Box bg='white' w='100%' p={4} color='black'>
                            <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                                <GridItem w='100%' h='10' ><a href="/portal/main/" title="Main Menu"><img src={BiaLogo} width={400} alt="Logo"/></a></GridItem>
                                <GridItem w='100%' h='10' />
                                <GridItem w='100%' h='10' paddingTop={2} textAlign="right"  ><Button bg={"steelblue"} color={"whitesmoke"} size='sm' onClick={() => navigate("/portal/main/")}>Menu</Button>&nbsp;<Button bg={"slategray"} color={"white"} size='sm' onClick={() => navigate("/logout")}>Logout</Button></GridItem>
                            </Grid>
                            <Center bg='white'>
                                <Outlet/>
                            </Center>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )

}