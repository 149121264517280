import {
    Button, Center,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import Wavelength from "../charts/wavelength";
import PlotChart from "../charts/plotChart";
import * as React from "react";


export default function ModalGraph(props){

    const { isOpen, onOpen, onClose } = useDisclosure()

            return (
                <>
                    {props.history && <Button onClick={onOpen} size={'xs'} >Chart</Button>}
                    {!props.history && <Button onClick={onOpen} >Open {props.title}</Button>}

                    <Modal isOpen={isOpen} onClose={onClose} size="xl">
                        <ModalOverlay />
                        <ModalContent  maxH="1200px" maxW="1000px">
                            <ModalHeader>{props.title}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Center>
                                    {props.type==="wave" && <Wavelength folder={props.folder}/>}
                                    {props.type==="plot" && <PlotChart data={props.data} is3D={props.is3D} myTypical={props.myTypical} myAtypical={props.myAtypical}/>}
                                </Center>
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme='blue' mr={3} onClick={onClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </>
            )
}