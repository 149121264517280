import axios from "axios";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { stringify } from "querystring-es3";
import { jwtDecode } from "jwt-decode";

const clientId = "6dbgajbkvcs9dkhc200p872bur";
const userPoolId = "eu-west-1_GytN1BQCt";
const url = "https://bia-int-dev.auth.eu-west-1.amazoncognito.com/oauth2/token";
const clientSecret = "18fvjhpk0ufu8tpdoijdsncne4irqoscpusu03ouisqre8cihpju";
const redirect_uri = process.env.REACT_APP_API_REDIRECT_URL;
const api_uri = process.env.REACT_APP_API_URL;

export async function CognitoCodeAuth(codeIn) {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  const data = stringify({
    grant_type: "authorization_code",
    redirect_uri: redirect_uri,
    client_id: clientId,
    client_secret: clientSecret,
    code: codeIn,
  });

  await axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((response) => {
      setCookie("Access", response.data.access_token, { path: "/" });
      setCookie("Refresh", response.data.refresh_token, { path: "/" });
      setCookie("ID", response.data.id_token, { path: "/" });
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function CognitoTokenRefresh() {
  const [cookies, setCookie] = useCookies(["user"]);

  const data = stringify({
    grant_type: "refresh_token",
    redirect_uri: redirect_uri,
    client_id: clientId,
    client_secret: clientSecret,
    code: cookies.Refresh,
  });

  await axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((response) => {
      setCookie("Access", response.data.access_token, { path: "/" });
      setCookie("Refresh", response.data.refresh_token, { path: "/" });
      setCookie("ID", response.data.id_token, { path: "/" });
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function VerifyToken() {
  try {
    const [cookies, setCookie] = useCookies(["user"]);
    const token = cookies.Access;

    // Verifier that expects valid access tokens:
    const verifier = CognitoJwtVerifier.create({
      region: "eu-west-1",
      userPoolId: userPoolId,
      clientId: clientId,
      tokenUse: "access",
    });
    const payload = await verifier.verify(token);
    return true;
  } catch (error) {
    return false;
  }
}

export function VerifyEmail() {
  const token = Cookies.get("ID");
  const email = jwtDecode(token)["email"].toString();
  return email.includes("@bia-analytical.com");
}

export function GetCogCode() {
  const token = Cookies.get("ID");
  return jwtDecode(token)["cognito:username"].toString();
}

export function CheckUserExistsInDB(codeIn) {
  var result = 0;
  var pathUser = api_uri + "user/" + codeIn;

  const config = { responseType: "json" };
  axios
    .get(pathUser, config)
    .then((response) => {
      result = response.data;
      Cookies.set("BiaID", result);
      return result;
    })
    .catch((error) => {
      return 0;
    });
}

export function GetAndStoreUserId(codeIn) {
  var result = 0;
  var pathUser = api_uri + "user/" + codeIn;

  const config = { responseType: "json" };
  axios.get(pathUser, config).then((response) => {
    result = response.data;
    Cookies.set("BiaID", result);
  });

  return result;
}
