import {Button, Link, Td, Tr} from "@chakra-ui/react";
import React, {useState} from "react";
import {postHistory} from "./HistoryFunctions";
import axios from "axios";
import ModalGraph from "./ModalGraph";

export default function HistoryLine(props){

    let IDToken = "eyJraWQiOiI2RUtUN0hmaDVPMGR6WkZGeDN4MGZIOVFLbkRTbklxMEdjRDY5XC9TbE43OD0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoib2lpNlUwRFBxSFk2MWVFVmJSYk1wUSIsInN1YiI6ImU5YjI5Yzg1LTA2MWUtNGMwNS1iYWIyLTM5YTgzYjg0YmQ5MSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0xLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMV9HeXROMUJRQ3QiLCJjb2duaXRvOnVzZXJuYW1lIjoiZTliMjljODUtMDYxZS00YzA1LWJhYjItMzlhODNiODRiZDkxIiwib3JpZ2luX2p0aSI6IjUzMTMzZTgzLTIyMTMtNDE0Yy1iMjIyLWEwM2FhYmFkODlmYiIsImF1ZCI6IjZkYmdhamJrdmNzOWRraGMyMDBwODcyYnVyIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTcwODM4NjUsImV4cCI6MTcxNzA4NzQ2NSwiaWF0IjoxNzE3MDgzODY1LCJqdGkiOiI0ZmFjMzYwYS1jNTgzLTRkNmYtYjgzMy02YjNhYzY2MmVlOWMiLCJlbWFpbCI6ImdhcmV0aEBiaWEtYW5hbHl0aWNhbC5jb20ifQ.VT42hEpTEjHDw6a51Dj1i46ZENXE6DuTSOQCqE8cpM6K1ToFREJah7_gBwLUVba4F5qOAdGg2UBI73ymzFA9RqNITAir_Kv7IyfmSO58DoJUcpD4z4ib9oaeYXOwL14IBnv98waiMJosAONwqNtCmfN416cVbixRqVeLDPh6WlwC0Fe3GjvJE3DTwTRipmZIsvZGE80BwirF-YxwyI9usYjg3NT-7JfkQl4HTrVzOWVWqj20h-ryKMHf5Jwx3l2X8ZHtWAyA9db4-lnP4BJmmmEWa7PCsi88xcvIT5Ry-ff8gEYnes2-dGryuDV2--pDsd4Oz0d2tRVGP_Tvklj72g";

    const [loaded, setLoaded] = React.useState(false);
    const [myData, setMyData] = useState("")
    const [typicalData, setTypicalData] = useState("")
    const [atypicalData, setAtypicalData] = useState("")



    let typData, atypData, twoData = "";
    let is3D=false;
    let isSpectra = false;

    //console.log(props.thisItem);
    const valuesIn = props.thisItem;
    let xValue= "N/A", yValue= "N/A", zValue= "N/A", commodityName = "N/A";
    let ActionType = "Averaged Spectra";

    // Load file routes for downloads if avg spectra
    const averageCSV = process.env.REACT_APP_API_S3 + valuesIn.folder + "/sample_avg.csv";
    const averageJSON = process.env.REACT_APP_API_S3 + valuesIn.folder + "/sample_avg.json";
    const logFile = process.env.REACT_APP_API_S3 + valuesIn.folder + "/logs.txt";
    const folder = valuesIn.folder;

    // If it's a chart get the points
    if(valuesIn.typeID!="3"){
        ActionType = valuesIn.name;
        commodityName = valuesIn.commodity;
        xValue = valuesIn.x;
        yValue = valuesIn.y;
        // If it's 3D
        if(valuesIn.typeID=="2"){
            zValue = valuesIn.z;
            is3D=true;
        }
    }
    else{
        isSpectra=true;
    }

    async function getModel(name, IDToken){

        let dataObject = {};
        dataObject.typical = [];
        dataObject.atypical = [];

        const url = "https://5lnc6b3wal.execute-api.eu-west-1.amazonaws.com/codelab-int/com/" + name;

        const client = axios.create({
            baseURL:url,
            headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : IDToken}
        })

        let response = client.get(url).then(response => {
            const jData = JSON.parse(response.data);
            var line = []
            for (var i = 0; i < jData.length; i++){
                var holdMe = {};
                line =jData[i];
                holdMe["x"] = line[3];
                holdMe["y"] = line[4];
                if(line[5]===true){
                    dataObject.typical.push(holdMe);
                }
                else{
                    dataObject.atypical.push(holdMe);
                }
            }

            const thisData = new Object();
            thisData.name = valuesIn.name;
            thisData.x = valuesIn.x;
            thisData.y = valuesIn.y;
            thisData.device = valuesIn.commodity;
            setMyData(thisData);
            setTypicalData(dataObject.typical);
            setAtypicalData(dataObject.atypical);
            return dataObject;
        });
    }

    async function get3DModel(name, IDToken){

        let dataObject = {};
        dataObject.typical = [];
        dataObject.atypical = [];

        const url = "https://5lnc6b3wal.execute-api.eu-west-1.amazonaws.com/codelab-int/3dcom/" + name;

        const client = axios.create({
            baseURL:url,
            headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : IDToken}
        })

        let response = client.get(url).then(response => {
            const jData = JSON.parse(response.data);
            console.log(jData);
            var line = []
            for (var i = 0; i < jData.length; i++){
                var holdMe = {};
                line =jData[i];
                holdMe["x"] = line[3];
                holdMe["y"] = line[4];
                holdMe["z"] = line[6];
                if(line[5]===true){
                    dataObject.typical.push(holdMe);
                }
                else{
                    dataObject.atypical.push(holdMe);
                }
            }

            const thisData = new Object();
            thisData.name = valuesIn.name;
            thisData.x = valuesIn.x;
            thisData.y = valuesIn.y;
            thisData.z = valuesIn.z;
            thisData.device = valuesIn.commodity;
            setMyData(thisData);

            setTypicalData(dataObject.typical);
            setAtypicalData(dataObject.atypical);
            return dataObject;
        });
    }

    if(!loaded){
        if(!isSpectra){
            if(is3D){
                get3DModel(valuesIn.commodity, IDToken).then(output => { setLoaded(true); } );
            }
            else{
                getModel(valuesIn.commodity, IDToken).then(output => { setLoaded(true); } );
            }
        }
        else{

        }
    }

    return (
        <Tr>
            <Td>{ActionType}</Td>
            <Td>{valuesIn.dateStamp}</Td>
            <Td>{commodityName}</Td>
            <Td isNumeric>{xValue}</Td>
            <Td isNumeric>{yValue}</Td>
            <Td isNumeric>{zValue}</Td>
            <Td>{isSpectra && <ModalGraph title="History Plot" data={myData} history={true} isSpectra={isSpectra} is3D={is3D} myTypical={typicalData} myAtypical={atypicalData} type="wave" folder={folder}/>}
                {!isSpectra && <ModalGraph title="History Plot" data={myData} history={true} isSpectra={isSpectra} is3D={is3D} myTypical={typicalData} myAtypical={atypicalData} type="plot"/>}

            </Td>
            <Td>
                {isSpectra && <Link href={averageCSV} isExternal={true}><Button size={'xs'} marginRight={'5px'}>CSV</Button></Link>}
                {isSpectra && <Link href={averageJSON} isExternal={true}><Button size={'xs'} marginRight={'5px'}>JSON</Button></Link>}
                {isSpectra && <Link href={logFile} isExternal={true}><Button size={'xs'}>LOG</Button></Link>}
            </Td>
        </Tr>
    )
}