import * as React from "react";
import { useState } from 'react';
import {Text, Stack, VStack } from '@chakra-ui/react'
import ModalGraph from "./ModalGraph";
import FilePole from "../modules/filePole";

export default function GetDownloads(props) {

    const averageCSV = props.URL + props.Folder + "/sample_avg.csv";
    const averageJSON = props.URL + props.Folder + "/sample_avg.json";
    const logFile = props.URL + props.Folder + "/logs.txt"

    const [loadGraph, setLoadGraph] = useState(false)

    function handleDataFromChild() {
        setLoadGraph(true);
    }


return(
    <div>
        <Stack>
            {!loadGraph && <VStack><Text as='b' fontSize='2xl' marginTop={2}>Processing....</Text></VStack>}
            {loadGraph && <VStack><Text as='b' fontSize='2xl' marginTop={2}>Processing Complete</Text></VStack>}
            <VStack><FilePole name="Avg spectra (CSV)" path={averageCSV} hasCallback="false" /></VStack>
            <VStack><FilePole name="Avg spectra (JSON)" path={averageJSON} hasCallback="true" sendDataToParent={handleDataFromChild}/></VStack>
            <VStack><FilePole name="Log File" path={logFile} hasCallback="false"/></VStack>

            {loadGraph && <VStack><ModalGraph title="Averaged Spectra" folder={props.Folder} type="wave"/></VStack>}
        </Stack>
    </div>
    )


}