import {AbsoluteCenter, Box, Center} from '@chakra-ui/react';
import BiaLogo from '../assets/Bia.jpg';
import StartButton from "../modules/StartButton";
import {AWSSecrets} from "../components/AWSSecrets";

const devURL=process.env.REACT_APP_API_BASE_URL;

export default function Root() {
    return (
            <AbsoluteCenter width={1024} axis='both' top="200">
            <Box bg='#48BB78' w='100%' p={2} color='white'>
                <Box bg='#F56565' w='100%' p={2} color='white'>
                    <Box bg='#4299E1' w='100%' p={2} color='white'>
                        <Box bg='white' w='100%' p={4} color='black'>

                            <Center bg='white'>
                                <img src={BiaLogo} width={400} alt="Logo" />
                                <Box p={2} m={1}>
                                    <strong>Welcome to the Bia Analytical lab portal.</strong><br/>
                                    This portal provides functions for automated laboratory tasks and is for internal use only.
                                </Box>
                                <StartButton URL={devURL}/>
                            </Center>


                        </Box>
                    </Box>
                </Box>
            </Box>
            </AbsoluteCenter>
    );
}