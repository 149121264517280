import React, {useState} from "react";
import {CognitoCodeAuth, VerifyToken} from '../components/AWSAuth';
import {Text, Image, Button, Center} from "@chakra-ui/react";
import { Box, Stack, HStack, VStack } from '@chakra-ui/react'
import { SimpleGrid } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
import HistoryList from "../modules/HistoryList";
import {Guarddog} from "../modules/Guarddog";
import Image1 from "../assets/Image1-250.jpg";
import Image2 from "../assets/Image2-250.jpg";
import Image3 from "../assets/Image3-250.jpg";
import Image4 from "../assets/Image4-250.png";


export default function MainMenu() {
    const searchParams = new URLSearchParams(window.location.search);
    const [validity, setValidity] = useState(false);

    if(searchParams.has('code')){
        // Get the auth code
        const codeIn=searchParams.get('code');
        CognitoCodeAuth(codeIn);
    }else{
        Guarddog();
    }


    const navigate = useNavigate();

    return (

        <VStack spacing='24px'>
            <Box marginY={30}>
                <Center border="none" bg='white'>
                    <VStack spacing='2px'>
                        <SimpleGrid columns={2} spacing={10} paddingTop={10} width={900}>
                            <Box bg='white' borderWidth='1px' borderRadius='lg' height='400px' boxShadow='2xl' textAlign="center" overflow='hidden'>
                                <Image src={Image1} borderBottom="1px"/>
                                <Text as='b' fontSize='2xl' marginTop={2}>Averaged Spectra</Text>
                                <Text padding={2}>Our powerful cloud functions will process multiple SPA files, for mutiple samples, and average the results per sample. </Text>
                                <Button  bg={"slategray"} color={"white"}  size='md' onClick={() => navigate("/portal/average")}>Averaged Spectra</Button>
                            </Box>
                            <Box bg='white' borderWidth='1px' borderRadius='lg' height='400px' boxShadow='2xl' textAlign="center" overflow='hidden'>
                                <Image src={Image2} borderBottom="1px"/>
                                <Text as='b' fontSize='2xl' marginTop={2}>Generate a 2D chart</Text>
                                <Text padding={2}>You can generate a plot based on provided sample data. Once you have your data you can generate a wavelength chart using the following button.</Text>
                                <Button bg={"slategray"} color={"white"}  size='md' onClick={() => navigate("/portal/plot")}>2D Plot Generator</Button>
                            </Box>
                            <Box bg='white' borderWidth='1px' borderRadius='lg' height='400px' boxShadow='2xl' textAlign="center" overflow='hidden'>
                                <Image src={Image3} borderBottom="1px"/>
                                <Text as='b' fontSize='2xl' marginTop={2}>Generate a 3D chart</Text>
                                <Text padding={2}>You can generate a interactive 3D graphic based on provided sample data. Once you have your data you can generate a wavelength chart using the following button.</Text>
                                <Button bg={"slategray"} color={"white"}  size='md' onClick={() => navigate("/portal/plot3D")}>3D Plot Generator</Button>
                            </Box>
                            <Box bg='white' borderWidth='1px' borderRadius='lg' height='400px' boxShadow='2xl' textAlign="center" overflow='hidden'>
                                <Image src={Image4} borderBottom="1px"/>
                                <Text as='b' fontSize='2xl' marginTop={2}>My Activity History</Text>
                                <Text padding={2}>Review your previous activities and run them again. Highly useful for Averaged Spectra analysis and file downloads.</Text>
                                <Button bg={"slategray"} color={"white"}  size='md' onClick={() => navigate("/portal/history")}>My Activity History</Button>
                            </Box>
                        </SimpleGrid>
                    </VStack>
                </Center>
            </Box>
        </VStack>

    );

}