import {Box, Button, Center, Grid, GridItem, Image, Input, InputGroup, InputLeftAddon, Select, SimpleGrid, Stack, Text, VStack} from "@chakra-ui/react";
import Image4 from "../assets/Image4-500.jpg";
import * as React from "react";
import {CheckIcon, RepeatIcon} from "@chakra-ui/icons";
import { useForm, SubmitHandler } from "react-hook-form"
import ModalGraph from "../modules/ModalGraph";
import axios from "axios";
import {useState} from "react";
import {Guarddog} from "../modules/Guarddog";
import {postHistory} from "../modules/HistoryFunctions";

export default function Plot3D() {

    Guarddog();

    const { register, handleSubmit } = useForm()
    const [submittedData, setSubmittedData] = React.useState();
    const [submitted, setSubmitted] = React.useState(false);
    const [typicalData, setTypicalData] = useState("")
    const [atypicalData, setAtypicalData] = useState("")

    const config = {responseType: 'json'};
    let IDToken = "eyJraWQiOiI2RUtUN0hmaDVPMGR6WkZGeDN4MGZIOVFLbkRTbklxMEdjRDY5XC9TbE43OD0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoib2lpNlUwRFBxSFk2MWVFVmJSYk1wUSIsInN1YiI6ImU5YjI5Yzg1LTA2MWUtNGMwNS1iYWIyLTM5YTgzYjg0YmQ5MSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0xLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMV9HeXROMUJRQ3QiLCJjb2duaXRvOnVzZXJuYW1lIjoiZTliMjljODUtMDYxZS00YzA1LWJhYjItMzlhODNiODRiZDkxIiwib3JpZ2luX2p0aSI6IjUzMTMzZTgzLTIyMTMtNDE0Yy1iMjIyLWEwM2FhYmFkODlmYiIsImF1ZCI6IjZkYmdhamJrdmNzOWRraGMyMDBwODcyYnVyIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTcwODM4NjUsImV4cCI6MTcxNzA4NzQ2NSwiaWF0IjoxNzE3MDgzODY1LCJqdGkiOiI0ZmFjMzYwYS1jNTgzLTRkNmYtYjgzMy02YjNhYzY2MmVlOWMiLCJlbWFpbCI6ImdhcmV0aEBiaWEtYW5hbHl0aWNhbC5jb20ifQ.VT42hEpTEjHDw6a51Dj1i46ZENXE6DuTSOQCqE8cpM6K1ToFREJah7_gBwLUVba4F5qOAdGg2UBI73ymzFA9RqNITAir_Kv7IyfmSO58DoJUcpD4z4ib9oaeYXOwL14IBnv98waiMJosAONwqNtCmfN416cVbixRqVeLDPh6WlwC0Fe3GjvJE3DTwTRipmZIsvZGE80BwirF-YxwyI9usYjg3NT-7JfkQl4HTrVzOWVWqj20h-ryKMHf5Jwx3l2X8ZHtWAyA9db4-lnP4BJmmmEWa7PCsi88xcvIT5Ry-ff8gEYnes2-dGryuDV2--pDsd4Oz0d2tRVGP_Tvklj72g";

    async function getModel(name, IDToken){

        let dataObject = {};
        dataObject.typical = [];
        dataObject.atypical = [];

        const url = "https://5lnc6b3wal.execute-api.eu-west-1.amazonaws.com/codelab-int/3dcom/" + name;

        const client = axios.create({
            baseURL:url,
            headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : IDToken}
        })

        let response = client.get(url).then(response => {
            const jData = JSON.parse(response.data);
            console.log(jData);
            var line = []
            for (var i = 0; i < jData.length; i++){
                var holdMe = {};
                line =jData[i];
                holdMe["x"] = line[3];
                holdMe["y"] = line[4];
                holdMe["z"] = line[6];
                if(line[5]===true){
                    dataObject.typical.push(holdMe);
                }
                else{
                    dataObject.atypical.push(holdMe);
                }
            }
            setTypicalData(dataObject.typical);
            setAtypicalData(dataObject.atypical);
            return dataObject;
        });
    }

    // Handle the submission of the form
    const onSubmit = (data) => {
        getModel(data.device, IDToken).then(output => {
            //console.log("Data is : " + data.name + " " + data.device);
            postHistory("3D Chart", data.name, data.device, data.x, data.y, data.z, "", "");
            setSubmittedData(data);
            setSubmitted(true);
            }
        );
    }





    return (
        <div>
            <VStack spacing='24px'>
                <Box marginY={30}>
                    <Center border="none" bg='white'>
                        <VStack spacing='2px'>
                            <SimpleGrid columns={2} spacing={10} paddingTop={10} width={900}>
                                <Box bg='white' borderWidth='1px' borderRadius='lg' height='450px' boxShadow='2xl' textAlign="center" overflow='hidden'>
                                    <Image src={Image4}/>
                                </Box>
                                <Box bg='white' borderWidth='1px' borderRadius='lg' minHeight='380px' boxShadow='2xl' textAlign="center">
                                    <VStack><Text as='b' fontSize='2xl' marginTop={2}>3D Plot Generator</Text></VStack>
                                    <Box p="0" m="4" maxW="395px" textAlign="center">Using the form below, select the commodity model you wish to use and enter the x and y values of your result.</Box>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <Stack spacing={5} align='stretch'>
                                            <Center>
                                                <Box w="300px" marginTop="7px" border="none">
                                                    <Grid templateColumns='repeat(3, 3fr)' width={'300px'} gap={2}>

                                                        <GridItem colSpan={3}>
                                                            <InputGroup>
                                                                <InputLeftAddon>Name</InputLeftAddon>
                                                                <Input size='md' {...register("name", { required: true, maxLength: 20 })} />
                                                            </InputGroup>
                                                        </GridItem>

                                                        <GridItem >
                                                            <InputGroup>
                                                                <InputLeftAddon>X</InputLeftAddon>
                                                                <Input size='md' {...register("x",{ required: true, min: -9999, max: 9999 })}/>
                                                            </InputGroup>
                                                        </GridItem>

                                                        <GridItem >
                                                            <InputGroup>
                                                                <InputLeftAddon>Y</InputLeftAddon>
                                                                <Input size='md' {...register("y", { required: true, min: -9999, max: 9999 })}/>
                                                            </InputGroup>

                                                        </GridItem>

                                                        <GridItem >
                                                            <InputGroup>
                                                                <InputLeftAddon>Z</InputLeftAddon>
                                                                <Input size='md' {...register("z", { required: true, min: -9999, max: 9999 })}/>
                                                            </InputGroup>

                                                        </GridItem>

                                                        <GridItem colSpan={3}>
                                                            <InputGroup>
                                                                <InputLeftAddon>Commodity</InputLeftAddon>
                                                                <Select {...register("device")}>
                                                                    <option value='BlackPepper'>Black Pepper</option>
                                                                    <option value='Rosemary'>Rosemary</option>
                                                                    <option value='Turmeric'>Turmeric</option>
                                                                </Select>
                                                            </InputGroup>
                                                        </GridItem>
                                                    </Grid>
                                                </Box>
                                            </Center>

                                            <Center bg='white' minH="30px">
                                                <Box h='30px'>
                                                    {!submitted && <Button type={"submit"} colorScheme='blue'><CheckIcon/>&nbsp;Submit</Button>}
                                                    {typicalData.length>0 && <Button type={"submit"} colorScheme='blue'><RepeatIcon/>&nbsp;Re-Generate</Button>}
                                                    {typicalData.length>0 && <ModalGraph title="Commodity Plot" data={submittedData} is3D={true} myTypical={typicalData} myAtypical={atypicalData} type="plot"/>}
                                                </Box>
                                            </Center>

                                        </Stack>
                                    </form>
                                </Box>
                            </SimpleGrid>
                        </VStack>
                    </Center>
                </Box>
            </VStack>

        </div>
    )
}