import axios from "axios";
import {json} from "react-router-dom";
import {isJsonObject} from "aws-jwt-verify/safe-json-parse";
import {GetCogCode} from "../components/AWSAuth"

const redirect_uri = process.env.REACT_APP_API_REDIRECT_URL;
const api_uri = process.env.REACT_APP_API_URL;

export async function getMyHistory(){

    const cogcode = GetCogCode();
    console.log("Cog code is : " + cogcode);

    let result = "Default";

    const config = {responseType: 'json'};
    let pathToCall = api_uri.toString() + "lastfive/" + cogcode;

    await axios.get(pathToCall, config)
        .then(response => {
            console.log("history = " + response.data);
            //console.log(JSON.parse(response.data));
            result=response.data;
        })
        .catch((error) => {
            console.log(error);
            result="Empty";
        });

    return result;

}

export async function postHistory(typein, name, model, x, y, z, folder, device){

    const cogcode = GetCogCode();
    console.log("Cog code is :" + cogcode);

    var pathUser = api_uri + "user/" + cogcode;
    // pathUser = api_uri + "user/NCC-1701";

    var userID = 0;
    var actionType = 0;

    const config = {responseType: 'json'};
    axios.get(pathUser, config)
        .then(response => {
            userID = 0;
            var JSONString = JSON.stringify(response.data);
            JSONString = JSONString.replace('[', '');
            JSONString = JSONString.replace(']', '');
            var JSONData = JSON.parse(JSONString);
            userID = JSONData.id;
        });

    var jsonAction = {};
    var jsonAction2 = {};
    var pathIn = "";

    switch(typein) {
        case "Averaged Spectra":
            jsonAction["device"] = device;
            jsonAction["folder"] = folder;
            pathIn = api_uri + "average/";
            actionType = 3;
            break;
        case "2D Chart":
            jsonAction["name"] = name;
            jsonAction["x_val"] = x
            jsonAction["y_val"] = y;
            jsonAction["commodity"] = model;
            pathIn = api_uri + "2D/";
            actionType =1;
            break;
        case "3D Chart":
            jsonAction["name"] = name;
            jsonAction["x_val"] = x
            jsonAction["y_val"] = y;
            jsonAction["z_val"] = z;
            jsonAction["commodity"] = model;
            pathIn = api_uri + "3D/";
            actionType=2;
            break;
    }


    axios.post(pathIn, jsonAction, config)
        .then(response => {
            var loggedItem = response.data[0];
            console.log("Logged the data profile of " + userID + "/" + actionType + "/" + loggedItem);
            jsonAction2["biauser"]=userID;
            jsonAction2["action_type"]=actionType;
            jsonAction2["action_id"]=loggedItem;
            const pathIn2 = api_uri + "history/";
            console.log(JSON.stringify(jsonAction2));

            axios.post(pathIn2, jsonAction2, config)
                .then(response => {console.log("Posted the history item " + response.data);});
            return(response.data);
        });

}
