import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Grid,
  HStack,
  Spacer,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Predictions() {
  const navigate = useNavigate();

  return (
    <div>
      <VStack spacing="24px">
        <Box marginY={30}>
          <Center border="none" bg="white">
            <VStack spacing="2px">
              <Container margin={"20px"} padding={"1px"} textAlign={"center"}>
                This page is for creating and viewing predictions of
                commodities. The table below shows the predictions you have
                submitted for processing.
              </Container>
              <Flex width="full" alignItems="center">
                <HStack spacing={2}>
                  <Button
                    leftIcon={<Search2Icon />}
                    colorScheme="blue"
                    variant="outline"
                  >
                    Filter
                  </Button>
                </HStack>
                <Button
                  leftIcon={<AddIcon />}
                  ml="auto"
                  colorScheme="green"
                  onClick={() => navigate("/portal/predictions/new")}
                >
                  New Prediction
                </Button>
              </Flex>
              <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr key="1">
                        <Th isNumeric={true}>ID</Th>
                        <Th>Name</Th>
                        <Th>Date</Th>
                        <Th>Commodity</Th>
                        <Th>Status</Th>
                        <Th>Set Count</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody></Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </VStack>
          </Center>
        </Box>
      </VStack>
    </div>
  );
}
