import React from "react";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import {AbsoluteCenter, Box, Button, Center} from "@chakra-ui/react";
import BiaLogo from "../assets/Bia.jpg";

export default function Logout() {

    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    removeCookie('Access', { path: '/' });
    removeCookie('Refresh', { path: '/' });
    removeCookie('ID', { path: '/' });

    const navigate = useNavigate();

    return(
        <AbsoluteCenter width={1024} axis='both' top="200">
            <Box bg='#48BB78' w='100%' p={2} color='white'>
                <Box bg='#F56565' w='100%' p={2} color='white'>
                    <Box bg='#4299E1' w='100%' p={2} color='white'>
                        <Box bg='white' w='100%' p={4} color='black'>

                            <Center bg='white'>
                                <img src={BiaLogo} width={400} alt="Logo" />
                                <Box p={2} m={1}>
                                    <strong>You are now logged out.</strong><br/>
                                    You have safely logged out of the platform and can be assured that your credentials
                                    are safe.<br/><br/>
                                    To log in again please return to the home page.
                                </Box>
                                <Button size='lg' onClick={() => navigate("/")}>Home</Button>
                            </Center>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </AbsoluteCenter>
    )

}