import {VerifyToken} from '../components/AWSAuth'
import {Button} from "@chakra-ui/react";
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

export default function StartButton(props){

    const [validToken, setValidToken] = useState(false);

    const navigate = useNavigate();

    VerifyToken().then(function(response) {
        setValidToken(response);
    }, function(error) {
        console.error("Failed!", error);
        return(<p>ERROR!</p>);
    })

    if(validToken){
        return(<Button colorScheme='blue'  p={10} paddingTop={6} paddingBottom={6} size='lg' onClick={() => navigate("/portal/main")}>Main Menu</Button>)
    }
    else{
        return(<Button colorScheme='blue' p={10} paddingTop={6} paddingBottom={6} size='lg' onClick={() => { window.location.href = props.URL; }}>Login/Register</Button>)
    }


}