import React, { useState, useEffect } from 'react';
import {CheckExists} from './FileGet';

export default function FilePole(props) {
    const [count, setCount] = useState(0);
    const [loaded, setLoaded] = useState(false)
    const path = props.path;

    function poll(path){
        CheckExists(path).then(response => {
            setLoaded(true);
        }).catch(e => {
            console.log(e);
            setCount(count+1);
        });
    }



    useEffect(() => {
        if(count<9 && !loaded){
            setTimeout(() => poll(path), 11000);
        }
        if(!loaded && count>9){
        }
        if(loaded){
            if(props.hasCallback==="true"){
                props.sendDataToParent();
            }
        }
    });


    if(count<9 && !loaded){
        return(<div>Checking for {props.name} : {count+1} of 10 </div>);
    }
    if(!loaded && count>9){
        return(<div>File ({props.name}) does not exist</div>);
    }
    if(loaded){
        return(<div><a href={props.path}>Download : {props.name}</a></div>);
    }

}