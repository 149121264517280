import {
  Box,
  Button,
  Center,
  Container,
  VStack,
  HStack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormErrorMessage,
  Spacer,
  IconButton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { DeleteIcon } from "@chakra-ui/icons";

const steps = [
  { title: "Define", description: "Define the job details" },
  { title: "Upload", description: "Upload the job files" },
  { title: "Create", description: "Validate and create the job" },
];

export default function NewPrediction() {
  const [step, setStep] = useState(1);
  const [commodityOptions, setCommodityOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileSets, setFileSets] = useState([]);
  //const [currentPage, setCurrentPage] = useState(1);
  //const [setsPerPage, setSetsPerPage] = useState(5);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const removeAll = () => {
    setFiles([]);
  };

  //schema for validation of the first step
  const formSchema = Yup.object().shape({
    jobName: Yup.string()
      .required("Job Name is required")
      .min(5, "Job Name must be at least 5 characters"),
    commodities: Yup.string().test(
      "not-default",
      "Commodity selection is required",
      (value) => value && value !== ""
    ),
    /* customerName: Yup.string()
      .required("Customer Name is required")
      .min(3, "Customer Name must be at least 3 characters"), */
  });

  // Fetch commodities from API
  useEffect(() => {
    const fetchCommodities = async () => {
      try {
        // Replace with actual API endpoint
        //const response = await axios.get('https://dummy-api.example.com/commodities');
        //setCommoditiesOptions(response.data);
        const commodityExample = [
          { value: "", label: "Select a commodity" },
          { value: "wheat", label: "Wheat" },
          { value: "corn", label: "Corn" },
          { value: "soybeans", label: "Soybeans" },
          { value: "rice", label: "Rice" },
        ];

        setCommodityOptions(commodityExample);
      } catch (error) {
        console.error("Error fetching commodities", error);
      }
    };

    fetchCommodities();
  }, []);

  // File drop zone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "text/csv": [".csv"],
      "text/tab-separated-values": [".tsv"],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
  });

  // Group files into sets
  useEffect(() => {
    const groupedSets = [];
    const processedFiles = [...files];

    while (processedFiles.length > 0) {
      const controlFiles = processedFiles.filter(
        (file) =>
          file.name.toUpperCase().includes("CTRL") &&
          ["A", "B", "C"].includes(
            file.name
              .replace(/\.[^/.]+$/, "")
              .slice(-1)
              .toUpperCase()
          )
      );
      const sampleFiles = processedFiles.filter(
        (file) =>
          !file.name.toUpperCase().includes("CTRL") &&
          ["A", "B", "C"].includes(
            file.name
              .replace(/\.[^/.]+$/, "")
              .slice(-1)
              .toUpperCase()
          )
      );

      if (controlFiles.length >= 3 || sampleFiles.length >= 3) {
        const controlSet = controlFiles.slice(0, 3);
        const sampleSet = sampleFiles.slice(0, 3);

        groupedSets.push({
          control: controlSet,
          sample: sampleSet,
        });

        // Remove processed files
        controlSet.forEach((file) => {
          const index = processedFiles.indexOf(file);
          if (index > -1) processedFiles.splice(index, 1);
        });
        sampleSet.forEach((file) => {
          const index = processedFiles.indexOf(file);
          if (index > -1) processedFiles.splice(index, 1);
        });
      } else {
        break;
      }
    }

    setFileSets(groupedSets);
  }, [files]);

  // Submit form handler
  const handleFinalSubmit = async (values) => {
    try {
      // Prepare form data
      const formData = {
        jobName: values.jobName,
        commodityType: values.commodityType,
        customerName: values.customerName,
        submissionDateTime: new Date().toISOString(),
        fileSets: fileSets.map((set) => ({
          control: set.control.map((file) => file.name),
          sample: set.sample.map((file) => file.name),
        })),
      };

      // Upload files to S3 (dummy implementation)
      const fileUploadPromises = fileSets
        .flatMap((set) => [...set.control, ...set.sample])
        .map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          // Replace with actual S3 upload endpoint
          return axios.post("https://dummy-api.example.com/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        });

      // Wait for file uploads
      await Promise.all(fileUploadPromises);

      // Submit form data to API
      await axios.post("https://dummy-api.example.com/submit", formData);

      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Submission error", error);
      alert("Submission failed");
    }
  };

  // Render first step (Job Name and Commodity)
  const renderFirstStep = (formikProps) => (
    <VStack spacing={4} width="100%">
      <Field type="text" name="jobName">
        {({ field, form }) => (
          <FormControl
            isInvalid={form.errors.jobName && form.touched.jobName}
            isRequired
          >
            <FormLabel>Job Name</FormLabel>
            <Input {...field} placeholder="Job Name" />
            <FormErrorMessage>{form.errors.jobName}</FormErrorMessage>
          </FormControl>
        )}
      </Field>

      <Field type="text" name="customerName">
        {({ field, form }) => (
          <FormControl
            isInvalid={form.errors.customerName && form.touched.customerName}
          >
            <FormLabel>Customer Name/Reference</FormLabel>
            <Input {...field} placeholder="Customer Name/Reference" />
            <FormErrorMessage>{form.errors.customerName}</FormErrorMessage>
          </FormControl>
        )}
      </Field>

      <Field name="commodities">
        {({ field, form }) => (
          <FormControl
            isInvalid={form.errors.commodities && form.touched.commodities}
          >
            <FormLabel htmlFor="commodities">Select Commodity</FormLabel>
            <Select {...field} id="commodities">
              {commodityOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{form.errors.commodities}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <HStack direction="row" spacing={4} alignContent="right" width="100%">
        <Spacer />
        <Button
          colorScheme="blue"
          onClick={() => {
            formikProps.validateForm().then((errors) => {
              if (Object.keys(errors).length === 0) {
                setStep(2);
                setActiveStep(activeStep + 1);
              } else {
                console.log(errors);
              }
            });
          }}
        >
          Next
        </Button>
      </HStack>
    </VStack>
  );

  // Render second step (File Upload)
  const renderFileUploadStep = () => (
    <VStack spacing={4} width="100%">
      <Box
        {...getRootProps()}
        border="3px dashed"
        borderColor={isDragActive ? "blue.500" : "gray.300"}
        p={10}
        textAlign="center"
        width="100%"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Text>Drop the files here ...</Text>
        ) : (
          <Text>
            Drag 'n' drop CSV or TSV files here, or click to select files
          </Text>
        )}
      </Box>

      {files.length > 0 && (
        <VStack width="100%">
          <Heading size="md">Selected Files ({files.length} files)</Heading>
          <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
            <TableContainer>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Filename</Th>
                    <Th>Actions</Th>
                  </Tr>
                  <Tr>
                    <Th></Th>
                    <Th>
                      <Button size="sm" color="teal" variant='outline' onClick={removeAll} leftIcon={<DeleteIcon />}>
                        Clear
                      </Button>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {files.map((file, index) => (
                    <Tr key={index}>
                      <Td>{file.name}</Td>
                      <Td>
                        <HStack width='100%' >
                          <Spacer />
                        <IconButton
                          variant='outline'
                          size="sm"
                          colorScheme="teal"
                          onClick={removeFile(file)}
                          icon={<DeleteIcon />}
                        />
                        <Spacer />
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </VStack>
      )}
      <HStack direction="row" spacing={4} align="right" width="100%">
        <Spacer />
        <Button
          colorScheme="blue"
          variant="outline"
          onClick={() => {
            setStep(1);
            setActiveStep(activeStep - 1);
          }}
        >
          Back
        </Button>

        <Button
          colorScheme="blue"
          onClick={() => {
            if (files.length >= 3 && files.length % 3 === 0) {
              setStep(3);
              setActiveStep(activeStep + 1);
            } else {
              alert(
                `Please upload at least 3 files (and multiples of 3). You have selected ${files.length} files`
              );
            }
          }}
        >
          Next
        </Button>
      </HStack>
    </VStack>
  );

  // Render third step (File Set Review)
  const renderFileSetReviewStep = () => (
    <VStack spacing={4} width="100%">
      <TableContainer width="100%">
        <Table variant="simple" size="md">
          <Thead>
            <Tr>
              <Th>Control Files</Th>
            </Tr>
          </Thead>
          <Tbody>
            {fileSets.map(
              (set, index) =>
                set.control.length > 0 && (
                  <Tr key={index}>
                    <Td>
                      {set.control.map((file) => (
                        <Text key={file.name}>{file.name}</Text>
                      ))}
                    </Td>
                  </Tr>
                )
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <TableContainer width="100%">
        <Table variant="simple" size="md">
          <Thead>
            <Tr>
              <Th>Sample Files</Th>
            </Tr>
          </Thead>
          <Tbody>
            {fileSets.map(
              (set, index) =>
                set.sample.length > 0 && (
                  <Tr key={index}>
                    <Td>
                      {set.sample.map((file) => (
                        <Text key={file.name}>{file.name}</Text>
                      ))}
                    </Td>
                  </Tr>
                )
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <HStack direction="row" spacing={4} align="right" width="100%">
        <Spacer />
        <Button
          colorScheme="blue"
          variant="outline"
          onClick={() => {
            setStep(2);
            setActiveStep(activeStep - 1);
          }}
        >
          Back
        </Button>
        <Button colorScheme="blue" onClick={() => alert('Submitting!')}>
          Submit Job
        </Button>
      </HStack>
    </VStack>
  );

  return (
    <div>
      <VStack spacing="24px">
        <Box marginY={30}>
          <Center border="none" bg="white">
            <VStack spacing="2px">
              <Container margin={"20px"} padding={"1px"} textAlign={"center"}>
                Create a new prediction job using one or more sets (including
                control sets).
              </Container>
              <Stepper index={activeStep}>
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>
                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
              <Container maxW="xl" py={10}>
                <Formik
                  initialValues={{
                    jobName: "",
                    commodites: "",
                    customerName: "",
                  }}
                  validationSchema={formSchema}
                  onSubmit={handleFinalSubmit}
                >
                  {(formikProps) => (
                    <Form>
                      <VStack spacing={6} width="100%">
                        {step === 1 && renderFirstStep(formikProps)}
                        {step === 2 && renderFileUploadStep()}
                        {step === 3 && renderFileSetReviewStep()}
                      </VStack>
                    </Form>
                  )}
                </Formik>
              </Container>
            </VStack>
          </Center>
        </Box>
      </VStack>
    </div>
  );
}
