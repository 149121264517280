import React, {useState} from "react";
import {Box, Button, Image, Text} from "@chakra-ui/react";
import Image3 from "../assets/Image3-250.jpg";

export default function HistoryList() {

    return (

        <Box bg='white' borderWidth='1px' borderRadius='lg' height='400px' boxShadow='2xl' textAlign="center" overflow='hidden'>
            <Image src={Image3} borderBottom="1px"/>
            <Text as='b' fontSize='2xl' marginTop={2}>History</Text>
        </Box>

    );

}