import React from "react";
import {Link, Center, AbsoluteCenter} from "@chakra-ui/react";
import { Box} from '@chakra-ui/react'
import BiaLogo from "../assets/Bia.jpg";



export default function NotAuthorised() {


    return (

        <AbsoluteCenter width={1024} axis='both' top="200">
            <Box bg='#48BB78' w='100%' p={2} color='white'>
                <Box bg='#F56565' w='100%' p={2} color='white'>
                    <Box bg='#4299E1' w='100%' p={2} color='white'>
                        <Box bg='white' w='100%' p={4} color='black'>

                            <Center bg='white'>
                                <Link href={'/'} title={"Click for homepage"}><img src={BiaLogo} width={400} alt="Logo" /></Link>
                                <Box p={2} m={1}>
                                    <strong>Sorry - You do not have permission to view this page.</strong><br/>
                                    This portal provides functions for automated laboratory tasks and is for internal use only. You do not have access to this site and therefor this page. If you feel this is a mistake then please try to log in again. If that fails please contact support for further assistance.
                                </Box>
                            </Center>


                        </Box>
                    </Box>
                </Box>
            </Box>

        </AbsoluteCenter>

    );

}