import {VerifyToken} from "../components/AWSAuth";
import { useNavigate } from "react-router-dom";
import {VerifyEmail} from "../components/AWSAuth";


export function Guarddog(){
    const navigate = useNavigate();

    VerifyToken().then(function(response) {
        if(!response){navigate("/");}
        if(!VerifyEmail()){navigate("/nope");}
        return(response);
    }, function(error) {
        console.error("Invalid Bia Credentials", error);
        navigate("/");
    })



}