import * as React from "react";
import { useState } from 'react';
import AWS from "aws-sdk/global";
import {S3, HeadObjectCommand, ListObjectsV2Command, S3Client} from "@aws-sdk/client-s3";
import {SQS, SendMessageCommand} from "@aws-sdk/client-sqs";
import { EmailIcon, InfoIcon} from "@chakra-ui/icons";
import {AbsoluteCenter, Box, Button, Center, Collapse, IconButton, Image, Input, Select, InputGroup, InputLeftElement, SimpleGrid, Text, VStack} from "@chakra-ui/react";
import { List, ListItem, Stack, StackDivider, UnorderedList } from '@chakra-ui/react'
import BiaLogo from "../assets/Bia.jpg";
import GetDownloads from "../modules/GetDownloads";
import {Guarddog} from "../modules/Guarddog";
import {useNavigate} from "react-router-dom";
import { MdHome, MdFileUpload } from "react-icons/md";
import Image1 from "../assets/Image1-500.jpg";
import Image2 from "../assets/Image2-250.jpg";
import ModalGraph from "../modules/ModalGraph";
import {postHistory} from "../modules/HistoryFunctions";


// AWS Variables for Cognito authentication
const PoolID = "eu-west-1:c2e8b055-336c-4e63-87ef-1685e1f5152b";
const REGION ='eu-west-1';
const S3_BUCKET ='bia-int-s3-averaging';
const S3_PATH = 'https://bia-int-s3-averaging.s3.eu-west-1.amazonaws.com';
const SQS_PATH_IS5 = 'https://sqs.eu-west-1.amazonaws.com/058264114857/BIA-INT-SQS-Averaging';
const SQS_PATH_SUMMIT = 'https://sqs.eu-west-1.amazonaws.com/058264114857/BIA-INT-SQS-Averaging-prosummit';

export default function AveragingForm(){

    Guarddog();


    const [show, setShow] = React.useState(false);
    const handleToggle = () => setShow(!show);


    AWS.config.region = REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({IdentityPoolId: PoolID});
    AWS.config.credentials.get(function(){});
    const myBucket = new S3({params: { Bucket: S3_BUCKET},region: REGION, credentials: AWS.config.credentials});

    // State variables for form values
    const [name, setName] = useState("Gareth");
    const [email, setEmail] = useState("gareth@bia-analytical.com");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [hasFiles, setHasFiles] = useState(false);
    const [hideMe, setHideMe] = useState(false);
    const [folder, setFolder] = useState("");
    const [device, setDevice] = useState("summitpro");
    let myPath = "";

    /*
    * HANDLERS FOR INTERACTION
    * */

    async function sendMyMessage (queueClient, sqsQueueUrl, jsonString) {

        const command = new SendMessageCommand({
            QueueUrl: sqsQueueUrl,
            DelaySeconds: 10,
            MessageBody: jsonString,
        });

        const response = await queueClient.send(command);
        return response;

    }

    // Handle new file selection events
    const handleFileEvent = (e) =>{
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
        setHasFiles(true);
    }

    // Handle adding files selected to state held file list
    const handleUploadFiles = (files)=>{

        let uploaded = [...uploadedFiles];
        // eslint-disable-next-line array-callback-return
        files.some((file)=> {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }

        })
        setUploadedFiles(uploaded);


    }

    // Handle the submission of the form
    const stageOneSubmit = (event) => {

        event.preventDefault();


        const uploaded = [...uploadedFiles];
        const paths =[];

        // Do upload here
        myPath = Date.now().toString();
        setFolder(myPath);
        uploaded.some((file) => {

            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET,
                Key: (myPath + "/" + file.name)
            };

           myBucket.putObject(params)
                .catch((err) => {
                    if (err) console.log(err)
                })

            paths.push(encodeURI((S3_PATH + "/" + myPath + "/" + file.name)))

        })

        // Create Json message payload
        const jasonObject = {
            name: {name}.name.toString(),
            email: {email}.email.toString(),
            folderID: myPath.toString(),
            files: [...paths]
        }

        // Send to Queue in here.
        // write to SQS
        //const credentials = fromEnv();
        //const sqsClient = new SQS({ credentials });


        if(device.toString()=="summitpro"){
            const myQueue = new SQS({params: {Queue: SQS_PATH_SUMMIT}, region: REGION, credentials: AWS.config.credentials});
            sendMyMessage(myQueue, SQS_PATH_SUMMIT, JSON.stringify(jasonObject));
        }
        else{
            const myQueue = new SQS({params: {Queue: SQS_PATH_IS5}, region: REGION, credentials: AWS.config.credentials});
            sendMyMessage(myQueue, SQS_PATH_IS5, JSON.stringify(jasonObject));
        }


        // Send the message
        postHistory("Averaged Spectra", "", device.toString(), 0, 0, 0, myPath.toString(), device.toString());

        setHideMe(true);


    }

    const onChange = (event) => {
        const value = event.target.value;
        setDevice(value);
    };

    const navigate = useNavigate();

    if(hideMe){

        return (


            <VStack spacing='24px'>
                <Box marginY={30}>
                    <Center border="none" bg='white'>
                        <VStack spacing='2px'>
                            <SimpleGrid columns={2} spacing={10} paddingTop={10} width={900}>
                                <Box bg='white' borderWidth='1px' borderRadius='lg' height='335px' boxShadow='2xl' textAlign="center" overflow='hidden'>
                                    <Image src={Image1}/>
                                </Box>
                                <Box bg='white' borderWidth='1px' borderRadius='lg' height='335px' boxShadow='2xl' textAlign="center" overflow='hidden'>



                                        <Box p="5" m="10" maxW="540px" >
                                            <GetDownloads URL="https://bia-int-s3-averaging.s3.eu-west-1.amazonaws.com/" Folder={folder}/>
                                        </Box>


                                </Box>
                            </SimpleGrid>
                        </VStack>
                    </Center>
                </Box>
            </VStack>







        )
    }
    else {
        return (

            <VStack spacing='24px'>
                <Box marginY={30}>
                    <Center border="none" bg='white'>
                        <VStack spacing='2px'>
                            <SimpleGrid columns={2} spacing={10} paddingTop={10} width={900}>
                                <Box bg='white' borderWidth='1px' borderRadius='lg' height='335px' boxShadow='2xl' textAlign="center" overflow='hidden'>
                                    <Image src={Image1}/>
                                </Box>
                                <Box bg='white' borderWidth='1px' borderRadius='lg' height='335px' boxShadow='2xl' textAlign="center" overflow='hidden'>
                                    <VStack><Text as='b' fontSize='2xl' marginTop={2}>Averaged Spectra</Text></VStack>
                                            <Box p="0" m="10" maxW="380px" textAlign="center">Using the form below, select the files you want to upload for spectra averaging.</Box>
                                            <form onSubmit={stageOneSubmit}>

                                                <Stack spacing={14} align='stretch'>

                                                    <Box w="400px" marginTop="7px" border="none">
                                                        <Center>
                                                            <Center>
                                                                <Select id={"device"} onChange={onChange}>
                                                                    <option value='summitpro'>SummitPro</option>
                                                                    <option value='is5'>IS5</option>
                                                                </Select>
                                                            </Center>
                                                            <Input id='fileUpload' p="5px" width="250px" type='file' multiple onChange={(e) => {
                                                                handleFileEvent(e)
                                                            }}></Input>
                                                        </Center>
                                                    </Box>


                                                    <Center bg='white' minH="30px">
                                                        <Box h='30px'>

                                                            <Button type={"submit"} colorScheme='blue' isDisabled={!hasFiles}><MdFileUpload/>&nbsp;Submit</Button>&nbsp;
                                                        </Box>
                                                    </Center>

                                                </Stack>
                                            </form>
                                </Box>
                            </SimpleGrid>
                        </VStack>
                    </Center>
                </Box>
            </VStack>








        )
    }

}