import {Box, Button, Center, Container, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, VStack} from "@chakra-ui/react";
import {getMyHistory} from "../modules/HistoryFunctions";
import {useEffect, useState} from 'react';
import HistoryLine from "../modules/HistoryLine";


export default function History() {

    const [historyData, setHistoryData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false)

    if(!isLoaded){
        getMyHistory().then(response => {
            let rawObject = JSON.parse(response);
            let newArray = [];
            for (let i = 0; i < rawObject.length; i++) {
                newArray.push(JSON.parse(rawObject[i]));
            }
            setHistoryData(JSON.parse(JSON.stringify(newArray)));
            console.log(historyData);
            setIsLoaded(true);
        });
    }




    //const rows = data.map(item => Object.values(item));

    return (
        <div>
            <VStack spacing='24px'>
                <Box marginY={30}>
                    <Center border="none" bg='white'>
                        <VStack spacing='2px'>

                            <Container margin={'20px'} padding={'20px'} textAlign={"center"}>
                                This page is designed to give you a reference for previous activities on CodeLab. The table below shows the last actions you performed with the latest being top of the list.
                            </Container>

                            <Box borderWidth='1px' borderRadius='lg' overflow='hidden'>
                            <TableContainer>
                                <Table variant='simple'>
                                    <Thead>
                                        <Tr key="1">
                                            <Th>Action Type</Th>
                                            <Th>Date</Th>
                                            <Th>Commodity</Th>
                                            <Th isNumeric={true}>X</Th>
                                            <Th isNumeric={true}>y</Th>
                                            <Th isNumeric={true}>Z</Th>
                                            <Th>Chart</Th>
                                            <Th>Files</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {isLoaded && historyData?.map((history, index) => (
                                            <HistoryLine thisItem={history} key={index}/>
                                        ))}

                                    </Tbody>
                                </Table>
                            </TableContainer>
                            </Box>
                        </VStack>
                    </Center>
                </Box>
            </VStack>
        </div>
    )


}