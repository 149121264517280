import axios from "axios";
import React, {useState} from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const config = {responseType: 'json'};

const colorArray =['#FF6633', '#FF33FF', '#00B3E6', '#999966', '#99FF99', '#B34D4D', '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF1A66', '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000', '#B33300', '#66664D', '#991AFF', '#4DB3FF', '#1AB399', '#E666B3', '#33991A', '#B3B31A', '#00E680', '#4D8066', '#809980', '#1AFF33', '#999933', '#FF3380',  '#4D80CC', '#9900B3', '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

export default function Wavelength(props) {

    const [data, setData] = useState([{name: 'Page A', uv: 400, pv: 2400, amt: 2400}, {name: 'Page B', uv: 100, pv: 1400, amt: 2400}, {name: 'Page C', uv: 200, pv: 2900, amt: 2400}])
    const [loaded, setLoaded] = useState(false)

    function renderLines (folder) {

        if(!loaded) {
            let path = 'https://bia-int-s3-averaging.s3.eu-west-1.amazonaws.com/' + folder + '/sample_avg.json';

            axios.get(path, config)
                .then(response => {
                    setData(response.data);
                });
            setLoaded(true);
        }
        var keys = Object.keys(data[0]).filter(val => val !== "Wavelength");
        const lines = keys.map((value) => (
            <Line
                key={value}
                name={value}
                type="monotone"
                dataKey={value}
                stroke={colorArray[(Math.floor(Math.random() * colorArray.length))]}
                strokeWidth={2}
                dot={false}
            />
        ));
        return lines;
    }


    return(
        <div>
            <LineChart width={800} height={600} data={data}>
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="Wavelength" />
                <YAxis />
                <Tooltip />
                <Legend />
                {renderLines(props.folder)}
            </LineChart>
        </div>
    )

}