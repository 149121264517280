import axios from "axios";


export async function GetFileAsJSON(pathIn){


    const config = {responseType: 'json'};
    axios.get(pathIn, config)
        .then(response => {
            return(response.data);
        });

}

export async function FileGet(pathIn){


    axios.get(pathIn)
        .then(response => {
            return(response.data);
        });

}

export async function CheckExists(pathIn){

    let len = 0;
    let stat = 0;
    let respData;

    try {
        await axios.get(pathIn)
            .then(response => {
                len = response.data.length;
                stat = response.status;
                respData = response.data;
            })
            .catch(error => {
                return error;
            });

        return new Promise((resolve, reject) => {
            if((stat === 200) && (len > 0)) {
                const successObject = {
                    msg: 'Success',
                    data:respData //...some data we got back
                }
                resolve(successObject);
            } else {
                const errorObject = {
                    msg: 'An error occurred',
                    error: 'File not found'
                }
                reject(errorObject);
            }
        });

    }
    catch (e) {
        console.log(e);
    }

}

