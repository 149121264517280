import * as React from "react";
import { CookiesProvider } from 'react-cookie';
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ChakraProvider} from '@chakra-ui/react'
import ErrorPage from "./errorPage";
import Logout from "./routes/logout";
import MainMenu from "./routes/mainMenu";
import NotAuthorised from "./routes/notauthorised";
import Portal from "./routes/Portal";
import Root from "./routes/root";
import AveragingForm from "./routes/AveragingForm";
import PlotDevice from "./routes/PlotDevice";
import Plot3D from "./routes/Plot3D";
import History from "./routes/History";
import './index.css';
import Predictions from "./routes/Predictions";
import NewPrediction from "./routes/NewPrediction";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
    },
    {
        path: "nope",
        element: <NotAuthorised />,
    },
    {
        path: "menu",
        element: <MainMenu />,
    },
    {
        path: "logout",
        element: <Logout />,
    },
    {
        path: "portal",
        element: <Portal />,
        children: [
            {
                path: "main",
                element: <MainMenu />,
            },
            {
                path: "average",
                element: <AveragingForm />,
            },
            {
                path: "plot",
                element: <PlotDevice />,
            },
            {
                path: "plot3D",
                element: <Plot3D />,
            },
            {
                path: "history",
                element: <History />,
            },
            {
                path: "predictions",
                element: <Predictions />,
            },
            {
                path: "predictions/new",
                element: <NewPrediction />,
            }
        ],
    },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<CookiesProvider>
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
</CookiesProvider>
);